import { Component, Input } from '@angular/core';



export interface BreadCrumbModel {
  title: string,
  url: string
}

@Component({
  selector: 'ui-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
  @Input() title: String | null = null;
  @Input() breadCrumbs: BreadCrumbModel[] = [];

}
