import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@ap/services/auth/auth.service';
import { environment } from 'src/environments/environment';

export interface MagicLinkModalData {
  email: string | null
}

@Component({
  selector: 'app-magic-link-modal',
  templateUrl: './magic-link-modal.component.html',
  styleUrls: ['./magic-link-modal.component.scss']
})
export class MagicLinkModalComponent {
  emailController:FormControl<string|null> = new FormControl('');
  constructor(public dialogRef: MatDialogRef<MagicLinkModalComponent>,
    private auth: AuthService,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: MagicLinkModalData,) {

  }
  requestLink = () => {
    this.data.email = this.emailController.value;
    let options: any | null;
    const redirect_url = this.route.snapshot.queryParamMap.get('redirect_url');
    if (redirect_url != null) {
      options = {
        emailRedirectTo: environment.baseUrl + redirect_url
      };
    }
    this.auth.signIn(this.data.email||'', options);

  }

}
