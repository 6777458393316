import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UiModule } from './ui/ui.module'
import { DialogModule } from '@angular/cdk/dialog'
import { MatDialogModule } from '@angular/material/dialog';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import { PageComponent } from './page/page.component';
import { CrmComponent } from './crm/crm.component';
import { MagicLinkModalComponent } from './login/magic-link-modal/magic-link-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';

import { SettingsModule } from '@ap/views/settings/settings.module';
import { APIconsRegistry, ApUiModule } from 'projects/ap-ui/src/public-api';
import { apIconAirplay, apIconHome01, apIconSettings04 } from 'projects/ap-ui/src/lib/icons';
import { SidemenuComponent } from './layout/sidemenu/sidemenu.component';



@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    LoginComponent,
    PageComponent,
    CrmComponent,
    MagicLinkModalComponent,
    SidemenuComponent,
    

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DialogModule,
    MatDialogModule,
    ReactiveFormsModule,
    UiModule,
    ApUiModule,
    BrowserAnimationsModule,

    //SettingsModule,

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private apIconRegistry: APIconsRegistry) {
    this.apIconRegistry.registerIcons([
      apIconAirplay,
      apIconHome01,
      apIconSettings04,
    ]);
  }
}
