<div class="px-3 sm:px-0">
    <div
        class="relative w-96 max-w-full transform rounded-3xl bg-white px-4 pb-4 pt-5 text-left shadow-2xl transition-all xs:w-full xs:max-w-md sm:p-6">
        <div *ngIf="data.email !== null">
            <div>
                <div class="mx-auto flex items-center justify-center">
                    <img class="h-60 " src="/assets/img/link_sent.png" />

                </div>
                <div class="mt-3 text-center sm:mt-5">
                    <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Magic Link Sent</h3>
                    <div class="mt-2">
                        <p class="text-md text-gray-500">Check your email. We sent and email to you at {{data.email}}.
                            Follow the link in the email to sign in</p>
                    </div>
                </div>
            </div>
            <div class="mt-5 sm:mt-6">
                <button type="button"
                    class="inline-flex w-full justify-center rounded-md bg-primary px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-primary/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Go
                    Back</button>
            </div>
        </div>
        <div *ngIf="data.email === null">
            <div>
                <div class="mx-auto flex items-center justify-center">
                    <img class="h-60 " src="/assets/img/link_sent.png" />

                </div>
                <div class="mt-3 text-center sm:mt-5">
                    <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Please enter your email</h3>
                    <div class="mt-2">
                        <div>
                            
                            <div class="mt-6">
                                <input id="email" name="email" type="email" autocomplete="email" [formControl] = "emailController"
                                    class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2">
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
            <div class="mt-5 sm:mt-6">
                <button type="button" (click)="requestLink()"
                    class="inline-flex w-full justify-center rounded-lg bg-primary px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-primary/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Get Magic Link
                </button>
            </div>
        </div>

    </div>

</div>