import { Injectable } from '@angular/core'
import {
  AuthChangeEvent,
  AuthError,
  AuthSession,
  createClient,
  Session,
  SupabaseClient,
  User,
} from '@supabase/supabase-js'
import { environment } from 'src/environments/environment'
import { Database } from 'src/types/supabase'

export enum UserRole {
  Prospect = 'PROSPECT',
  Agent = 'AGENT',
  Admin = 'ADMIN',
  Staff = 'STAFF',
}
export interface Profile {
  user_id?: string
  first_name: string
  last_name: string
  full_name: string
  website: string
  avatar_url: string
  role:UserRole
}

@Injectable({
  providedIn: 'root',
})
export class SupabaseService {
  public client: SupabaseClient
  _session: AuthSession | null = null

  constructor() {
    this.client = createClient(environment.supabaseUrl, environment.supabaseKey);

  }

  get session() {
    this.client.auth.getSession().then(({ data }) => {
      this._session = data.session
    })
    return this._session
  }

  profile(user: User) {
    return this.client
      .from('profiles')
      .select(`username, full_name,website, avatar_url,role`)
      .eq('id', user.id)
      .single()
  }

  authChanges(callback: (event: AuthChangeEvent, session: Session | null) => void) {
    return this.client.auth.onAuthStateChange(callback)
  }

  signIn(email: string, options:any|null) {
    return this.client.auth.signInWithOtp({ email,options:options });
  }
  signInPassword(email: string,password:string, options:any|null) {
    
    return this.client.auth.signInWithPassword({email:email,password:password} );
  }

  signOut(): Promise<{error: AuthError | null}> {
    return this.client.auth.signOut();
  }

  updateProfile(profile: Profile) {
    const update = {
      ...profile,
      updated_at: new Date(),
    }

    return this.client.from('profiles').upsert(update)
  }

  downLoadImage(path: string) {
    return this.client.storage.from('avatars').download(path)
  }

  uploadAvatar(filePath: string, file: File) {
    return this.client.storage.from('avatars').upload(filePath, file)
  }
}
