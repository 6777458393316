import { Component } from '@angular/core';
import { MagicLinkModalComponent, MagicLinkModalData } from './magic-link-modal/magic-link-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@ap/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(
    private dialog: MatDialog,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {

  }
  login() {
    this.dialog.open<MagicLinkModalComponent,MagicLinkModalData>(MagicLinkModalComponent,{panelClass:'reset',data:{email:null}})

  }
  loginPassword = async ()=>{
    let options: any | null;
    const redirect_url = this.route.snapshot.queryParamMap.get('redirect_url');
   
    let {data,error} = await this.auth.signInPassword('sherry@anpac.net','skiutah',options);
    if (!error){
      this.router.navigateByUrl(redirect_url || '/')
    }
  }

}
