import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@ap/services/auth/auth.service';
import { Profile } from '@ap/services/supabase/supabase.service';
import { User } from '@supabase/supabase-js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  showMenu:boolean = false;
  user:Profile|null = null;
  constructor(public auth:AuthService,public router: Router){
    auth.$profile.subscribe(profile=>{
      this.user = profile;
      
    })
    console.log(environment.baseUrl);
  }
  toggleMenu(){
    this.showMenu = !this.showMenu
  }
  logout(){
    this.auth.signOut();
  }

}
