import {ChangeDetectionStrategy, Component, ElementRef, HostBinding, Inject, Input, Optional, ViewEncapsulation} from '@angular/core';
import { APIconsRegistry } from './ap-ui-icon.service';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'ap-icons',
    template: `
        <ng-content></ng-content>
    `,
    styles: [':host::ng-deep svg{width: 100%};:host::ng-deep svg path{vector-effect: non-scaling-stroke}; :host{display:inline-block; stroke:currentColor}'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class APIconsComponent {
    private svgIcon: SVGElement | undefined;
    @Input() class:string = '';
    @Input()
    set name(iconName: string) {
        if (this.svgIcon) {
            this.element.nativeElement.removeChild(this.svgIcon);
        }
        const svgData = this.apIconRegistry.getIcon(iconName);
        this.svgIcon = this.svgElementFromString(svgData||'');
        this.element.nativeElement.appendChild(this.svgIcon);
    }

    constructor(private element: ElementRef, private apIconRegistry: APIconsRegistry,
                @Optional() @Inject(DOCUMENT) private document: any) {
    }

    private svgElementFromString(svgContent: string): SVGElement {
        const div = this.document.createElement('DIV');
        div.class = this.class;
        div.innerHTML = svgContent;
        return div.querySelector('svg') || this.document.createElementNS('http://www.w3.org/2000/svg', 'path');
    }
}