import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent {
  @Input() url:string = '';
  @Input() text:string = '';
  @Input() routerLinkActiveOptions:any = {};

}
