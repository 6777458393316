import { Component } from '@angular/core';
import { AuthService } from '@ap/services/auth/auth.service';


@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent {
  constructor(public auth:AuthService){

  }
  logout = ()=>{
    this.auth.signOut();
  }

}
