import { NgModule } from '@angular/core';
import { APIconsComponent } from './ap-ui.component';
import { apIconAirplay, apIconPlayCircle } from './icons';
import { VideoComponent } from './components/video/video.component';
import { APIconsRegistry } from './ap-ui-icon.service';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [
    APIconsComponent,
    VideoComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    APIconsComponent,
    VideoComponent
  ]
})
export class ApUiModule { 
  constructor(private apIconRegistry: APIconsRegistry) {
    this.apIconRegistry.registerIcons([
      apIconPlayCircle
    ]);
  }
}
