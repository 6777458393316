import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
// Importing video.js
import videojs from 'video.js';
//import Player from "video.js/dist/types/player";


interface VideoOptions {
  fluid?: boolean | undefined,
  aspectRatio?: string | undefined,
  autoplay?: boolean | undefined,
  playbackRates?: number[],
  sources: {
    src: string,
    type: string,
  }[],
}

@Component({
  selector: 'ap-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoComponent implements OnInit, OnDestroy {
  @ViewChild('target', { static: true }) target: ElementRef | undefined;

  // Setting the video js options
  // which we need to use
  @Input() options: VideoOptions | undefined;
  playing: boolean = false;
  player: videojs.Player | undefined;
  constructor(
    private elementRef: ElementRef,
  ) {
  
  }

  // Initializing a video player on component initialization
  ngOnInit() {
    if (!this.options?.playbackRates) {
      this.options!.playbackRates = [0.5, 1, 1.5, 2];
    }
    this.player = videojs(this.target?.nativeElement, this.options,
      () => {
        console.log('video Player on Ready', this);
      });
    this.player.on('pause', () => {
      this.playing = false
    })
    this.player.on('play', () => {
      this.playing = true
    })
    
  }

  // Destroying the player on component destruction
  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
  play = () => {
    this.playing = true;
    this.player?.play()?.then(() => {
      this.playing = true;
    });
  }

}




// creating the videojs player component
