import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import { CrmComponent } from './crm/crm.component';
import { PageComponent } from './page/page.component';
import { ProfileGuard } from './guards/profile.guard';
import { ProspectGuard } from './guards/prospect.guard';

const routes: Routes = [

  {
    path: '',
    component: LayoutComponent,
    canActivate:[ProfileGuard], 
    children: [
      { path: '', redirectTo: 'albums', pathMatch: 'full' },
      { path: 'albums', component: PageComponent },
      {
        path: 'settings',
        loadChildren: () => import('@ap/views/settings/settings.module').then(m => m.SettingsModule)
      },
     
    ]
  },
  {
    path: 'crm',
    canActivate:[ProfileGuard], 
    loadChildren: () => import('@ap/views/crm/crm.module').then(m => m.CrmModule)
  },
 
  {
    path: 'careers',
    canActivate:[ProspectGuard], 
    loadChildren: () => import('@ap/views/recruiting/recruiting.module').then(m => m.RecruitingModule)
  },
  { path: 'login', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
