import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { RouterModule } from '@angular/router';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';

import { ApUiModule } from 'projects/ap-ui/src/public-api';



@NgModule({
  declarations: [
    MenuItemComponent,
    PageHeaderComponent,
    ClickOutsideDirective,

  ],
  imports: [
    CommonModule,
    RouterModule,
    ApUiModule
  ],
  exports: [
    MenuItemComponent,
    PageHeaderComponent,

    ClickOutsideDirective
  ]
})
export class UiModule { }
